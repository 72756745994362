import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Td,
  Text,
  Th,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import debounce from 'lodash/debounce'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Container, ResultsTable } from 'starterUiComponents'
import useNeedsMembership from "../../hooks/useNeedsMembership"
import Loader from "../../components/ui-components/Loader"

const WARRANTY_SHOPS_QUERY = graphql`
  query {
    allMysqlWarrantyShops {
      nodes {
        shop_name
        phone
        mail_add
        contact
        city
        zip
        state
        id
      }
    }
  }
`

const WarrantyShopsPage = () => {
  const allWarrantyShops =
    useStaticQuery(WARRANTY_SHOPS_QUERY).allMysqlWarrantyShops.nodes

  const [warrantyShops, setWarrantyShops] = React.useState([])
  const [status, setStatus] = React.useState('iddle')

  const { handleSubmit, register } = useForm({
    defaultValues: {
      zipCode: '',
      state: '',
    },
  })

  const noResults =
    status === 'iddle'
      ? 'Please enter your information in the fields above to search for results.'
      : 'No information is available for your selection(s), please try your search again with another selection.'

  const onSubmit = async (data, e) => {
    e.preventDefault()
    // setStatus('loading')
    const { zipCode, state } = data

    if (!zipCode && !state) {
      setStatus('success')
      setWarrantyShops([])
      return
    }
    let filteredShops = allWarrantyShops
    if (zipCode) {
      filteredShops = allWarrantyShops.filter(
        (shop) =>
          shop.zip && shop.zip.toLowerCase().includes(zipCode.toLowerCase())
      )
      if (state) {
        filteredShops = filteredShops.filter(
          (shop) =>
            shop.state && shop.state.toLowerCase().includes(state.toLowerCase())
        )
      }
    }
    if (state) {
      filteredShops = filteredShops.filter(
        (shop) =>
          shop.state && shop.state.toLowerCase().includes(state.toLowerCase())
      )
    }
    setStatus('success')
    setWarrantyShops(filteredShops)
  }

  const showLoaderPage = useNeedsMembership()

  if (showLoaderPage) {
    return <Loader />
  }

  return (
    <Container>
      <Text className="font-bold font-heading text-l ">
        Search by State or Zip Code:
      </Text>

      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <form>
        <div className="p-8 mb-5 bg-darkBlue">
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={5}
            alignItems="flex-end"
            sx={{
              label: {
                color: 'white',
                fontStyle: 'italic',
                mb: 2,
                fontSize: 'base',
              },
              '.chakra-stack': {
                width: '100%',
                // alignItems: 'flex-start',
              },
            }}
          >
            <Stack
              w={{ base: '100%', lg: '50% !important' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <VStack>
                <FormControl id="state">
                  <FormLabel>Search by State Initials</FormLabel>
                  <Input
                    ref={register}
                    name="state"
                    type="text"
                    placeholder="State (TWO LETTERS): "
                    onChange={debounce(handleSubmit(onSubmit), 300)}
                  />
                </FormControl>
              </VStack>
              <VStack>
                <FormControl id="zipCode">
                  <FormLabel>Search by Zip Code</FormLabel>
                  <Input
                    ref={register}
                    name="zipCode"
                    type="text"
                    placeholder="Enter a Zip Code"
                    onChange={debounce(handleSubmit(onSubmit), 300)}
                  />
                </FormControl>
              </VStack>
            </Stack>
            <div className="flex justify-center w-full lg:justify-end lg:w-1/2">
              <Button
                variant="lightBlue"
                width="auto"
                fontSize={{ base: 'sm', md: 'base' }}
                to={'#'}
                onClick={(e) => {
                  e.preventDefault()
                  fetch('https://atsgco.wpengine.com/wp-content/uploads/2021/09/AdvantageWarrantyGuidelines-3242022.pdf', {
                    method: 'GET',
                  })
                    .then((response) => response.blob())
                    .then((blob) => {
                      const url = window.URL
                        .createObjectURL(blob);
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'AdvantageWarrantyGuidelines.pdf');
                      document.body.appendChild(link);
                      link.click();
                    })
                }}
              >
                Warranty Shop guidelines [PDF]
              </Button>
            </div>
          </Stack>
        </div>
      </form>

      <div className="mb-5 italic font-normal text-lightGrey font-heading">
        PLEASE NOTE: Before you send your customer to any of these shops, please
        call and confirm that they are still in the warranty program and are
        willing to do the work under ATSG Guidelines. If they answer "No" please
        contact ATSG immediately so we can remove them from the Warranty
        Program.
      </div>

      <ResultsTable
        sx={{ tr: { display: 'block' } }}
        headers={
          <>
            <Th w={300}>SHOP NAME</Th>
            <Th w={300}>Contact Name</Th>
            <Th w={300}>Phone</Th>
            <Th w={300}>ADDress</Th>
          </>
        }
      >
        {warrantyShops && warrantyShops.length > 0
          ? warrantyShops.map((warrantyShop) => (
            <Tr key={warrantyShop.id}>
              <Td w={300}>{warrantyShop.shop_name}</Td>
              <Td w={300}>{warrantyShop.contact}</Td>
              <Td w={300}>{warrantyShop.phone}</Td>
              <Td w={300}>
                {warrantyShop.mail_add}, {warrantyShop.city},{' '}
                {warrantyShop.state}, {warrantyShop.zip}
              </Td>
            </Tr>
          ))
          : warrantyShops &&
          !warrantyShops.length && <div className="mt-5">{noResults}</div>}
      </ResultsTable>
    </Container>
  )
}

export default WarrantyShopsPage
